import { useTranslation } from "next-i18next";
import { Button, ButtonToggle } from "@looksrare/uikit";
import { ClassicGamesHistorySort } from "../../types";

interface SortOptionsProps {
  sort: ClassicGamesHistorySort;
  setSort: (sort: ClassicGamesHistorySort) => void;
}

export const SortOptions = ({ sort, setSort }: SortOptionsProps) => {
  const { t } = useTranslation();
  const sortOptionLabels = [
    { key: ClassicGamesHistorySort.MINE, text: t("flipper::Mine") },
    { key: ClassicGamesHistorySort.ALL, text: t("flipper::All") },
    { key: ClassicGamesHistorySort.BIGGEST, text: t("flipper::Big Wins") },
    { key: ClassicGamesHistorySort.LUCKIEST, text: t("flipper::Lucky Wins") },
  ];
  return (
    <ButtonToggle>
      {sortOptionLabels.map(({ key, text }) => (
        <Button
          key={key}
          variant="outline"
          onClick={() => setSort(key)}
          bgColor={sort === key ? "interactive-02" : "transparent"}
          color={sort === key ? "link-01" : "link-02"}
          size="sm"
          data-id={`recent-filter-${key}`}
        >
          {text}
        </Button>
      ))}
    </ButtonToggle>
  );
};
