import { Menu, MenuButton, MenuList, Button } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Chevron, currencyTokenIconMap } from "@looksrare/uikit";
import type { SetCurrencyHandler } from "../../types";
import { EntryCurrencyMenuItem } from "./EntryCurrencyMenuItem";

interface EntryCurrencyMenuProps {
  currencies: Currency[];
  currentCurrency: Currency;
  onSelectCurrency: SetCurrencyHandler;
  isDisabled?: boolean;
}

export const EntryCurrencyMenu = ({
  currencies,
  currentCurrency,
  onSelectCurrency,
  isDisabled,
}: EntryCurrencyMenuProps) => {
  const SelectedCurrencyIcon = currencyTokenIconMap[currentCurrency];

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            leftIcon={<SelectedCurrencyIcon />}
            rightIcon={<Chevron direction="down" />}
            as={Button}
            size="xs"
            colorScheme="secondary"
            isDisabled={isDisabled}
          >
            {currentCurrency}
          </MenuButton>
          {/* display hack is needed to avoid horizontal scroll while the menuList is initially not rendered */}
          <MenuList display={isOpen ? "block" : "none"}>
            {currencies.map((currency) => (
              <EntryCurrencyMenuItem key={currency} currency={currency} onClick={() => onSelectCurrency(currency)} />
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
