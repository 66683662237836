import { EstimateGamePointsQueryVariables, graphql as generatedGql } from "@looksrare/yolo-games-gql-typegen";
import { graphql, RQueryOptionsForFetcher, useDebouncedValue } from "@looksrare/utils";
import { useQuery } from "@tanstack/react-query";

const estimateGamePoints = async ({
  amountPerRoundWei,
  numberOfRounds,
  currency,
}: EstimateGamePointsQueryVariables) => {
  const estimateGamePointsQUery = generatedGql(/* GraphQL */ `
    query EstimateGamePoints($amountPerRoundWei: BigNumber!, $currency: Address!, $numberOfRounds: Int!) {
      estimateGamePoints(amountPerRoundWei: $amountPerRoundWei, currency: $currency, numberOfRounds: $numberOfRounds)
    }
  `);
  const res = await graphql({
    query: estimateGamePointsQUery,
    params: { amountPerRoundWei, numberOfRounds, currency },
  });
  return res.estimateGamePoints;
};

export const useEstimateGamePoints = (
  { amountPerRoundWei, numberOfRounds, currency }: EstimateGamePointsQueryVariables,
  options?: RQueryOptionsForFetcher<typeof estimateGamePoints>
) => {
  const debouncedAmountPerRoundWei = useDebouncedValue(amountPerRoundWei, 500);
  const debouncedNumberOfRounds = useDebouncedValue(numberOfRounds, 500);

  return useQuery({
    queryKey: ["classic-games", "points", debouncedAmountPerRoundWei, debouncedNumberOfRounds, currency],
    queryFn: () =>
      estimateGamePoints({
        amountPerRoundWei: debouncedAmountPerRoundWei,
        numberOfRounds: debouncedNumberOfRounds,
        currency, // No need to debounce this as changing it is already a pretty complex user interaction
      }),
    ...options,
  });
};
