import { useAccount } from "wagmi";
import { HStack, MenuItem, type MenuItemProps } from "@chakra-ui/react";
import { Text, currencyTokenIconMap } from "@looksrare/uikit";
import { type Currency } from "@looksrare/config";
import {
  formatToSignificant,
  getCurrencyDisplayDecimals,
  useAddressesByNetwork,
  useEthBalance,
  useTokenBalance,
} from "@looksrare/utils";

interface EntryCurrencyMenuItemProps extends MenuItemProps {
  currency: Currency;
}

export const EntryCurrencyMenuItem = ({ currency, ...props }: EntryCurrencyMenuItemProps) => {
  const { address } = useAccount();
  const addresses = useAddressesByNetwork();
  const currencyAddress = addresses[currency];
  const isEth = currency === "ETH";

  const { data: ethBalanceWei } = useEthBalance(address!, { enabled: !!address && isEth });
  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: currencyAddress,
    address: address!,
    queryOptions: { enabled: !!address && !isEth },
  });

  const balanceWei = (isEth ? ethBalanceWei : tokenBalanceWei) ?? 0n;
  const Icon = currencyTokenIconMap[currency];

  return (
    <MenuItem {...props}>
      <HStack justifyContent="space-between" width="100%">
        <HStack spacing={2}>
          <Icon />
          <Text textStyle="detail" bold>
            {currency}
          </Text>
        </HStack>
        <Text textStyle="detail">{formatToSignificant(balanceWei, getCurrencyDisplayDecimals(currency, "large"))}</Text>
      </HStack>
    </MenuItem>
  );
};
