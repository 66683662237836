import { Stack, StackProps } from "@chakra-ui/react";
import { SearchIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

export const EmptyHistoryTable = (props: StackProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={4} width="100%" alignItems="center" maxWidth="512px" {...props}>
      <SearchIcon boxSize={16} color="interactive-03" />
      <Stack spacing={2} textAlign="center" width="100%" maxWidth="480px">
        <Text textStyle="heading-03" bold>
          {t("flipper::You new here?")}
        </Text>
        <Text color="text-02" whiteSpace="normal">
          {t("flipper::You do know you need to play a game to see the results, right?")}
        </Text>
      </Stack>
    </Stack>
  );
};
