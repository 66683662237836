import { Stack, StackProps } from "@chakra-ui/react";
import { ConnectWalletButton, Text, WalletIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

export const ConnectWalletPrompt = (props: StackProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={4} width="100%" alignItems="center" {...props}>
      <WalletIcon boxSize={16} color="interactive-03" />
      <Stack spacing={2} textAlign="center">
        <Text textStyle="heading-03" bold>
          {t("Connect Wallet")}
        </Text>
        <Text color="text-02">{t("flipper::Connect your wallet to see your results.")}</Text>
      </Stack>
      <ConnectWalletButton width="218px" />
    </Stack>
  );
};
