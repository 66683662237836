import { Skeleton, Flex, FlexProps } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { Address } from "viem";
import { formatToSignificant } from "@looksrare/utils";
import { useEstimateGamePoints } from "../network/queries/points";

interface Props extends FlexProps {
  amountPerRoundWei: bigint;
  currencyAddress: Address;
  numberOfRounds: number;
}

export const PointsPreview = ({ amountPerRoundWei, currencyAddress, numberOfRounds, ...props }: Props) => {
  const { t } = useTranslation();
  const { data: estimatedPoints, isFetching } = useEstimateGamePoints({
    amountPerRoundWei,
    currency: currencyAddress,
    numberOfRounds,
  });
  return (
    <Flex justifyContent="space-between" {...props}>
      <Text color="text-03" textStyle="detail">
        {t("Enter now to earn")}
      </Text>
      {isFetching ? (
        <Skeleton height="20px">{t("{{points}} Pts", { points: 1000 })}</Skeleton>
      ) : (
        <Text textStyle="detail" bold>
          {t("{{points}} Pts", { points: formatToSignificant(estimatedPoints || 0n, 4, { decimals: 0 }) })}
        </Text>
      )}
    </Flex>
  );
};
