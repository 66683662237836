import { useTranslation } from "next-i18next";
import { Box, Flex, IconButton, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { LaunchOpenInNewIcon, Link, OverflowMenuHorizontalIcon, Text } from "@looksrare/uikit";
import { getExplorerLink } from "@looksrare/utils";
import { ChainId } from "@looksrare/config";

interface TransactionHashMenuProps {
  drawnTransactionHash?: string | null;
  drawingTransactionHash?: string | null;
  chainId: ChainId;
}

/**
 * Verify Randomness: $drawnTransactionHash
 * Play Transaction: $drawingTransactionHash
 *
 * If neither is available, show a dash.
 */
export const TransactionHashMenu = ({
  drawnTransactionHash,
  drawingTransactionHash,
  chainId,
}: TransactionHashMenuProps) => {
  const { t } = useTranslation();
  const hasTransactionToVerify = drawnTransactionHash || drawingTransactionHash;

  return hasTransactionToVerify ? (
    <Box onClick={(e) => e.stopPropagation()}>
      <Menu>
        <MenuButton as={IconButton} size="xs" variant="ghost" colorScheme="primary">
          <OverflowMenuHorizontalIcon />
        </MenuButton>
        <MenuList zIndex="dropdown">
          {drawnTransactionHash && (
            <MenuItem as={Link} isExternal href={getExplorerLink(drawnTransactionHash, "transaction", chainId)}>
              <Flex justifyContent="space-between" width="100%" alignItems="center">
                <Text textStyle="detail">{t("flipper::Verify Randomness")}</Text>
                <LaunchOpenInNewIcon color="text-03" boxSize={5} />
              </Flex>
            </MenuItem>
          )}

          {drawingTransactionHash && (
            <MenuItem as={Link} isExternal href={getExplorerLink(drawingTransactionHash, "transaction", chainId)}>
              <Flex justifyContent="space-between" width="100%" alignItems="center">
                <Text textStyle="detail">{t("flipper::Play Transaction")}</Text>
                <LaunchOpenInNewIcon color="text-03" boxSize={5} />
              </Flex>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  ) : (
    <Text color="text-disabled">—</Text>
  );
};
