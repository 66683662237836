import type { Currency } from "@looksrare/config";

export type SetCurrencyHandler = (newCurrency: Currency) => void;

// These sort options are mapped to the graphql sort option in each query
export enum ClassicGamesHistorySort {
  ALL = "ALL",
  MINE = "MINE",
  BIGGEST = "WIN_AMOUNT_DESC",
  LUCKIEST = "LUCKY_WIN_DESC",
}

/** Tracks the lifecycle of a game, spans from transactions to actual gameplay.
 * This covers a wider range than the game.status as returned by the BE
 */
export enum LocalGameStatus {
  IDLE = "IDLE",
  SUBMITTING_TRANSACTION = "SUBMITTING_TRANSACTION",
  AWAITING_ONCHAIN_CONFIRMATION = "AWAITING_ONCHAIN_CONFIRMATION",
  POLLING_BE = "POLLING_BE",
  ANIMATING = "ANIMATING",
  FINISHED = "FINISHED",
  CLAIMING = "CLAIMING",
}
