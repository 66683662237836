import { ChainId, type Currency, IS_TESTNET } from "@looksrare/config";
import {
  BigIntish,
  FromDecimalsOptions,
  formatNumberToLocale,
  formatToSignificant,
  fromDecimals,
  getLocalStorageItem,
  getStorageKey,
  setLocalStorageItem,
} from "@looksrare/utils";
import { ClassicGamesHistorySort } from "./types";

export const getChainId = () => {
  return IS_TESTNET ? ChainId.BLAST_SEPOLIA : ChainId.BLAST_MAINNET;
};

interface getFormattedAmountOptions {
  largeNumberDecimals?: number;
  smallNumberDecimals?: number;
  largeNumberTreshold?: number;
  fromDecimalsOptions?: FromDecimalsOptions;
}

/**
 *
 * @param amount amount you want to format
 * @param options.largeNumberDecimals number of decimals to show for larger numbers eg. 12,345.6789
 * @param options.smallNumberDecimals number of decimals to show for smaller numbers eg. 0.1234
 * @param options.largeNumberTreshold number above which to show largeNumberDecimals
 * @returns
 */
export const getFormattedAmount = (amount?: BigIntish | null, options: getFormattedAmountOptions = {}) => {
  if (!amount || Number(amount) === 0) {
    return "—";
  }

  const {
    largeNumberDecimals = 4,
    smallNumberDecimals = 4,
    largeNumberTreshold = 0,
    fromDecimalsOptions = {},
  } = options;

  const amountFloat = Number(fromDecimals(amount, fromDecimalsOptions));
  const minAmount = 1 / 10 ** smallNumberDecimals;

  if (amountFloat < minAmount && amountFloat > 0) {
    return `<${formatNumberToLocale(minAmount, 0, smallNumberDecimals)}`;
  }

  return formatToSignificant(
    amount,
    amountFloat > largeNumberTreshold ? largeNumberDecimals : smallNumberDecimals,
    fromDecimalsOptions
  );
};

/**
 * Flipper & LaserBlast are not using Zustand for state management so we need
 * to roll our own persist for currency
 */
interface GetLastUsedCurrencyArgs {
  game: string;
  supportedCurrencies: Currency[]; // Used for validation
  fallback: Currency;
}

export const getLastUsedCurrency = ({ game, supportedCurrencies, fallback }: GetLastUsedCurrencyArgs) => {
  const savedCurrency = getLocalStorageItem<Currency>(getStorageKey(`last_used_currency_${game}`));
  if (!savedCurrency || !supportedCurrencies.includes(savedCurrency)) {
    return fallback;
  }
  return savedCurrency;
};

interface SetLastUsedCurrencyArgs {
  game: string;
  currency: Currency;
}
export const setLastUsedCurrency = ({ game, currency }: SetLastUsedCurrencyArgs) => {
  setLocalStorageItem(getStorageKey(`last_used_currency_${game}`), currency);
};

/**
 * Map our UI's sort options to the corresponding graphql value
 */
export const getClassicGamesGraphqlSort = (sort: ClassicGamesHistorySort) => {
  if (sort === ClassicGamesHistorySort.BIGGEST) {
    return "WIN_AMOUNT_DESC";
  }
  if (sort === ClassicGamesHistorySort.LUCKIEST) {
    return "LUCKY_WIN_DESC";
  }
  return "NEWEST";
};
