import { AddFundsButton, Button, ConnectWalletButton, ErrorIcon, FastForwardIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { VStack, StackProps } from "@chakra-ui/react";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { PointsPreview } from "./PointsPreview";

interface Props extends StackProps {
  isComingSoon?: boolean;
  isWalletBlocked: boolean;
  hasInsufficientBalance: boolean;
  currencyAddress: Address;
  numberOfRounds: number;
  amountPerRoundWei: bigint;
  canSkip: boolean;
  onSkip?: () => void;
}

/** Render a points preview and `children` if the game can be started. Replaces it with the correct CTA otherwise*/
export const PlayCTAWrapper = ({
  isComingSoon,
  isWalletBlocked,
  hasInsufficientBalance,
  canSkip,
  currencyAddress,
  numberOfRounds,
  amountPerRoundWei,
  children,
  onSkip,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  if (isComingSoon) {
    return (
      <Button width="100%" isDisabled>
        {t("Coming Soon!")}
      </Button>
    );
  }

  if (isWalletBlocked) {
    return (
      <Button width="100%" rightIcon={<ErrorIcon boxSize={5} />} variant="outline" colorScheme="red" isDisabled>
        {t("Wallet Blocked")}
      </Button>
    );
  }

  if (!isConnected) {
    return <ConnectWalletButton width="100%" connectButtonText={t("Connect Wallet")} />;
  }

  if (canSkip) {
    return (
      <Button width="100%" onClick={onSkip} rightIcon={<FastForwardIcon />}>
        {t("Skip to Results")}
      </Button>
    );
  }

  if (hasInsufficientBalance) {
    return (
      <AddFundsButton width="100%" variant="solid" colorScheme="secondary">
        {t("Add Funds")}
      </AddFundsButton>
    );
  }

  return (
    <VStack alignItems="stretch" width="100%" {...props}>
      <PointsPreview
        currencyAddress={currencyAddress}
        numberOfRounds={numberOfRounds}
        amountPerRoundWei={amountPerRoundWei}
      />
      {children}
    </VStack>
  );
};
