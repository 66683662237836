import { GameConfigManagerAbi } from "@looksrare/config";
import { useGameAddresses } from "@looksrare/uikit";
import { usePublicClient } from "wagmi";
import { useCallback } from "react";

export const useGetVrfParams = () => {
  const { GAME_CONFIG_MANAGER } = useGameAddresses();
  const publicClient = usePublicClient();
  return useCallback(async () => {
    if (!publicClient) {
      throw new Error("No public client found");
    }
    const vrfParams = await publicClient.readContract({
      address: GAME_CONFIG_MANAGER,
      abi: GameConfigManagerAbi,
      functionName: "vrfParameters",
    });
    const [coordinator, subscriptionId, callbackGasLimit, minimumRequestConfirmations, vrfFee, keyHash] = vrfParams;
    return { coordinator, subscriptionId, callbackGasLimit, minimumRequestConfirmations, vrfFee, keyHash };
  }, [GAME_CONFIG_MANAGER, publicClient]);
};
