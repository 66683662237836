import { DataLayerEventNames, fromDecimals, useCoinPrices, useSendAnalyticsEvent } from "@looksrare/utils";
import { Currency } from "@looksrare/config";
import { useAccount } from "wagmi";
import { useCallback } from "react";

export const useSendGameEntryAnalyticsEvent = (
  eventName:
    | DataLayerEventNames.YOLO_DEPOSIT
    | DataLayerEventNames.MORD_DEPOSIT
    | DataLayerEventNames.PTB_DEPOSIT
    | DataLayerEventNames.FLIPPER_DEPOSIT
    | DataLayerEventNames.QUANTUM_DEPOSIT
    | DataLayerEventNames.LASER_BLAST_DEPOSIT
    | DataLayerEventNames.DONT_FALL_IN_DEPOSIT
) => {
  const { address } = useAccount();
  const { data: prices } = useCoinPrices();
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  return useCallback(
    (entryValueBi: bigint, currency: Currency) => {
      const entryValueString = fromDecimals(entryValueBi);
      const currencyPriceInfo = prices?.[currency.toLowerCase()];
      const totalPriceUsd = currencyPriceInfo ? parseFloat(entryValueString) * currencyPriceInfo.price : 0;
      const totalPriceEth = prices?.eth?.price ? totalPriceUsd / prices.eth.price : 0;

      sendAnalyticsEvent({
        event: eventName,
        connectedWalletAddress: address,
        totalPriceEth: totalPriceEth.toString(),
        totalPriceUsd: totalPriceUsd.toString(),
      });
    },
    [address, eventName, prices, sendAnalyticsEvent]
  );
};
